/*eslint-disable */
const $ = window.jQuery = require('jquery');

import {bus} from '@/main';
import countries from '@/services/country.json'
import {mapGetters} from 'vuex';

export default {
  name: 'public-ngo-list',
  components: {
  },
  props: [],
  data () {
    return {
      pubNgoInfoPopupState:false,
      pubNgoInfoPopupStateClass:false,
      allPublicNgoListData:null,
      allPublicNgoListDataResults:[],
      pubNgoInfoData:null,
      countryList:[]
    }
  },
  computed: {
    ...mapGetters([
      'getAllPublicNgoListGetters'
    ]),
    getAllPublicNgoListMethod(){
      this.allPublicNgoListData = this.$store.getters.getAllPublicNgoListGetters.data
      return;
    },
    setIntialStatus() {
      if (this.allPublicNgoListData) {
        this.allPublicNgoListDataResults = this.allPublicNgoListData.response
      }
      return;
    },
  },
  mounted () {
    this.getAllPublicNgoListMethod
    this.setIntialStatus
    bus.$emit('showHeaderbtn', false);
    bus.$emit('showHeaderSection', true); 
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key],
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
  },
  methods: {
    pubNgoInfoPopupMethod(data){
      this.pubNgoInfoData = data
      $(window).scrollTop(0);
      this.pubNgoInfoPopupState = true
      document.body.classList.add('popup_open_body')
      setTimeout(() => {
        this.pubNgoInfoPopupStateClass = true
      }, 10);
    },
    clickedOutside() {
      this.pubNgoInfoPopupStateClass = false
      setTimeout(() => {
        this.pubNgoInfoPopupState = false
        document.body.classList.remove('popup_open_body')
        this.pubNgoInfoData = null
      }, 600);
    },
    getCountryName(countryCode) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        country = country.name
      } else {
        country = ''
      }
      return country;
    },
  },
  watch:{
    getAllPublicNgoListMethod(){
      return this.$store.getters.getAllPublicNgoListGetters;
    },
    setIntialStatus() {
      if (this.allPublicNgoListData) {
        this.allPublicNgoListDataResults = this.allPublicNgoListData.response
      }
      return;
    },
  },
  beforeRouteLeave: function (to, from, next) {
    document.body.classList.remove('popup_open_body')
    next();
  },
}